import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = [ 'anchor' ]
  static excludedElms = [ 'A', 'BUTTON' ]

  navigate(event) {
    if (this.constructor.excludedElms.includes(event.target.nodeName) || window.getSelection().toString()) return

    this.anchorTarget.click()
  }
}
