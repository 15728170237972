import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['submit', 'taxAccounting']

  copy(event) {
    const copyTo = document.getElementById(
      event.target.getAttribute('data-form-copy-to-value'))
    if (!copyTo || copyTo.value) return

    copyTo.value = event.target.value
  }

  enableSubmit(event) {
    if (this.element.checkValidity() === true) {
      this.submitTarget.disabled = false
    }
  }

  validate(event) {
    this.element.classList.add('was-validated')

    if (this.element.checkValidity() === false) {
      Rails.stopEverything(event)
    }
  }

  forceTaxInclusive(event) {
    if (event.target.value == 'tax_exempt') {
      this.taxAccountingTarget.value = 'tax_inclusive'
      this.taxAccountingTarget.disabled = true
    } else {
      this.taxAccountingTarget.disabled = false
    }
  }
}
