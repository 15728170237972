import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['taxCategory']

  updateTaxCategory(event) {
    if (!this.hasTaxCategoryTarget) return

    const accountTaxCategory =
      event.target.options[event.target.selectedIndex].dataset.taxCategory
    if (!accountTaxCategory) return

    this.taxCategoryTarget.value = accountTaxCategory
  }
}
