import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["template", "debit", "credit"]

  connect() {
    this.sumAmount('debit')
    this.sumAmount('credit')
  }

  add_association(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g,
      new Date().valueOf())
    this.templateTarget.insertAdjacentHTML('beforebegin', content)
  }

  disableDebitOrCredit(event) {
    let amount_type = ''
    let counter_type = ''
    if (/debit/.test(event.target.id)) {
      amount_type = 'debit'
      counter_type = 'credit'
    } else {
      amount_type = 'credit'
      counter_type = 'debit'
    }

    const counter_element = document.getElementById(event.target.id.replace(amount_type, counter_type))

    counter_element.disabled = event.target.value > 0 ? true : false
  }

  fillCounterSplitAmount(event) {
    if (this.element.querySelectorAll(".nested-fields:not([style*='display: none'])").length > 2) return;

    if (event.target.id === 'entry_splits_attributes_0_amount_cents_debit') {
      document.getElementById('entry_splits_attributes_1_amount_cents_credit')
        .value = event.target.value;
      this.sumAmount('credit');
    }
    else if (event.target.id === 'entry_splits_attributes_0_amount_cents_credit') {
      document.getElementById('entry_splits_attributes_1_amount_cents_debit')
        .value = event.target.value;
      this.sumAmount('debit');
    }
  }

  updateDebitTotal(event) {
    this.sumAmount('debit')
  }

  updateCreditTotal(event) {
    this.sumAmount('credit')
  }

  sumAmount(side) {
    const css = side === 'debit' ? '.debit-amount' : '.credit-amount'
    const elms = this.element.querySelectorAll(css)
    let sum = 0;
    elms.forEach(elm => sum += Number(elm.value))

    this[`${side}Target`].textContent = sum.toLocaleString('en')
  }

  remove_association(event) {
    event.preventDefault()

    const item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.querySelector("input[name*='amount_cents_debit']").value = 0
    item.querySelector("input[name*='amount_cents_credit']").value = 0
    item.querySelectorAll("[required='required']")
      .forEach(elm => elm.required = false)
    item.style.display = 'none'
    this.sumAmount('debit')
    this.sumAmount('credit')
  }
}
