import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['sideMenu']

  initialize() {
    $('[data-toggle="tooltip"]').tooltip('show')

    /*
    Google tag manager code
    dataLayer = dataLayer || [];
    dataLayer.push({
     'event':'pageView',
     'virtualUrl': location.href
    });
    */

    /*
     * Google Analytics
     * Don't use location url as warned below.
     * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
     */
    gtag('config', 'UA-157574352-1', {'page_path': location.pathname + location.search});
  }

  hideNav(event) {
    if (event.target.type !== 'submit' && $(event.target).parents('#navbarNavAltMarkup').length === 0
      && this.sideMenuTarget.classList.contains('show')) {
      event.preventDefault();
      $('.navbar-collapse').collapse('hide');
    }
  }
}
